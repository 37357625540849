import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
/*!

==============================================================
* Taken Initial from Argon Design System React - v1.1.2
===============================================================

* Product Page: 

* Coded by Mukesh

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import React from "react";
//import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "./views/Index.js";
import Landing from "./views/examples/Landing.js";
import Login from "./views/examples/Login.js";
import Profile from "./views/examples/Profile.js";
import Register from "./views/examples/Register.js";
import Equity from "./views/examples/Equity.js";
import ProjectQuery from "./FE_new_components/ProjectQuery.js";
import TermsConditions from "./FE_new_components/TermsConditions.js";
import DesignServices from "./FE_new_components/DesignServices.js"
/*import FWServices from "./FE_new_components/FWServices.js"*/
import FWLinuxServices from "./FE_new_components/FWLinuxServices.js"
import MgmtServices from "./FE_new_components/MgmtServices.js"
import SiliconEnggConsultancy from "./FE_new_components/SiliconEnggConsultancy.js"
/*
const Privacypolicy = () => {
console.log("Index.js : 42")
  return (
    <div>
      <h1>Privacy policy</h1>
      <iframe
        title="External Content"
        src={"${process.env.PUBLIC_URL}/Privacy_policy.htm"}
        width="100%"
        height="600px"
        frameBorder="0"
      />
    </div>
  );
};

const GeneralTermsCond = () => {
console.log("Index.js : 58")
  return (
    <div >
      <h1>General Terms and conditions</h1>
      <iframe
        title="External Content"
        src={"${process.env.PUBLIC_URL}/General_Terms_and_cond_website.html"}
        width="100%"
        height="600px"
        frameBorder="0"
      />
    </div>
  );
};


const CGTermsCond = () => {
console.log("Index.js : 75")
  return (
    <div>
      <h1>General Terms and conditions</h1>
      <iframe
        title="External Content"
        src={"${process.env.PUBLIC_URL}/CG_Terms_of_service.html"}
        width="100%"
        height="600px"
        frameBorder="0"
        
      />
    </div>
  );
};

*/

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("Index.js : 94")
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Index />} />
      <Route path="/landing-page"     exact element={<Landing />} />
      <Route path="/login-page"       exact element={<Login />} />
      <Route path="/register-page"    exact element={<Register />} />
      <Route path="/equity-page"      exact element={<Equity />} /> 
      <Route path="/ProjectQuery-page" exact element={<ProjectQuery />} />
      <Route path="/DesignServices-page" exact element={<DesignServices />} />
      <Route path="/FWLinuxServices-page" exact element={<FWLinuxServices/>} />
      <Route path="/MgmtServices-page" exact element={<MgmtServices/>} />   
      <Route path="/SiEngConsult-page" exact element={<SiliconEnggConsultancy/>}/> 
      <Route path="/terms-conditions-page" exact element={<TermsConditions />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  </BrowserRouter>
);


reportWebVitals();
